<mat-card appearance="outlined" id="card" style="text-align: center;">
    <h1><span i18n>Clever budget:</span> {{month}}</h1>
        <h2><span i18n>Tarif</span></h2>
        <div class="flex-row">
            <div class="tarif"> 
                <p matLine><span i18n>System tarif:</span> {{systemTarif}} <span i18n>Kr/KWh</span></p>
                <p matLine><span i18n>Net tarif (average):</span> {{averageTarifNight}} <span i18n>Kr/KWh</span></p>
            </div>
            <div class="power">
                <img class="image" mat-card-image src="assets/ev_charger.svg" alt="photo">
            </div>
        </div>
   
        <h2><span i18n>Average</span></h2>
        <div class="flex-row"> 
            <div class="tarif">
                <p matLine><span i18n>Average day (24h):</span> {{averageKwhPriceIncludingVat}} <span i18n>Kr/KWh</span></p>
                <p matLine><span i18n>Average Night (23-06):</span> {{averageKwhPriceIncludingVatNight}} <span i18n></span>Kr/KWh</p>
            </div>
            <div class="power">
                <img class="image" mat-card-image src="assets/ev_charger.svg" alt="photo">
            </div>
        </div>

        
        <h2><span i18n>Clever estimate:</span></h2>
        <div class="flex-row">
            
            <div class="tarif">
                <p matLine><span i18n>Refund price:</span> {{refusionPrice}} <span i18n>Kr/KWh</span></p>
                <p matLine><span i18n>Energy addendum:</span> {{energyAddendum}} <span i18n>Kr/KWh</span></p>
            </div>
            <div class="power">
                <img class="image" mat-card-image src="assets/ev_charger.svg" alt="photo">
            </div>
        </div>
</mat-card>
