<mat-card appearance="outlined">
    <mat-list-item  *ngIf="groupConfig$ | async; let config" class="list-group">
       <!--  {{config[0].notificationUrl}}  -->
    <mat-card>
        <h1><span i18n>Group Settings</span> : {{getGroup()}}</h1>
    </mat-card>
    <mat-card>
        <form [formGroup]="settingsForm" (submit)="submitForm()">
            <mat-card>          
                <mat-form-field class="form-element">
                    <mat-label><span i18n>Group Server Name</span></mat-label>
                    <input type="text" matInput formControlName="serverName" placeholder="enter a server friendly name" />
                </mat-form-field>
            </mat-card>
            <mat-card>
                <!-- ApiKey -->
                <mat-form-field class="form-element">
                    <mat-label><span i18n>Server ApiKey</span></mat-label>
                    <input
                    type="text"
                    matInput
                    formControlName="apiKey"
                    placeholder="xxA_key_xx"
                    />

                </mat-form-field>
            </mat-card>
    <!-- Server Url -->
            <mat-card>
                <mat-form-field class="form-element">
                    <mat-label><span i18n>Notification URL</span></mat-label>
                    <input
                    type="text"
                    matInput
                    formControlName="serverUrl"
                    placeholder="http://serverUrl.com"
                    />
                </mat-form-field>
    </mat-card>
    <mat-card-actions>
        <button
            [disabled]="!settingsForm.valid"
            mat-flat-button
            color="primary"
            type="submit"
            class="col-3 mt-2 mb-3"
        >
        <span i18n>Save</span>
        </button>
    </mat-card-actions>
    <br />
    <ng-template [ngIf]="submitStatus">
        <span i18n>Form submitted succesfully!</span>
    </ng-template>
    </form>
        </mat-card>
</mat-list-item>  
</mat-card>