<!--
<ng-template ngFor let-lang [ngForOf]="languages">
  <span *ngIf="lang.code === localeId">{{lang.label}} </span>
</ng-template>
-->
<div class="mat-app-background" [class.dark]="secondThemeSelected">
<div *ngIf="appType$ | async; let app_type">  
  <a *ngIf="app_type == 'carpool'">
    {{ setDocTitle( 'Porsevej Carpool' ) }}
  </a>  
  <a *ngIf="app_type == 'cartrack'">
    {{ setDocTitle( 'Porsevej Cartrack' ) }}
  </a>  
  <a *ngIf="app_type == 'powertrack'">
    {{ setDocTitle( 'Porsevej Powertrack' ) }}
  </a>
<!-- Toolbar -->
<mat-toolbar color="primary" class="header">
  <button mat-icon-button [matMenuTriggerFor]="appMenu">
  <!--  <mat-icon>{{ isMenuOpen ? 'menu_open' : 'menu' }}</mat-icon>-->
  <mat-icon>{{ 'menu' }}</mat-icon>
  </button>
  <a *ngIf="app_type == 'cartrack'">
    <span>CT</span>
  </a>
  <a *ngIf="app_type == 'carpool'">
    <span>CP</span>
  </a>
<a *ngIf="app_type == 'powertrack'">
  <span>PT</span>
</a>

  <span class="col2"></span>
  <ng-container *ngIf=firstGroup$>
    {{ firstGroup$ | async }}
  </ng-container>
  
  <span class="example-fill-remaining-space"></span>
  {{ loggedInUserFirstName$ | async }}
   <!--  {{app}}    -->

  <button mat-icon-button [matMenuTriggerFor]="auth">
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #auth="matMenu">
    <a *ngIf="loggedIn$ | async">
      <button mat-menu-item (click)="signOut()">
        <mat-icon>lock</mat-icon>
        <span i18n>Log out</span>
        {{ loggedInUserName$ | async }}
      </button>
    </a> 
    <a *ngIf="loggedOut$ | async">
      <button mat-menu-item (click)="signIn()">
        <mat-icon>lock_open</mat-icon>
        <span i18n>Log in</span>
      </button>
    </a> 


  </mat-menu>

</mat-toolbar>

<mat-menu #appMenu="matMenu">
  <button mat-menu-item [routerLink]="['/home']" routerLinkActive="active">
    <mat-icon>home</mat-icon><span i18n>Home</span>
  </button>
  <a *ngIf="adminUser$ | async">
    <button mat-menu-item [routerLink]="['/users']" routerLinkActive="active">
      <mat-icon>person_pin</mat-icon><span i18n>Users</span></button>
    <button mat-menu-item [routerLink]="['/groups']" routerLinkActive="active">
      <mat-icon>person_pin</mat-icon><span i18n>Groups</span></button> 
  </a>
  <a *ngIf="loggedIn$ | async">
    <a *ngIf="!(adminUser$ | async)">
      <button mat-menu-item [matMenuTriggerFor]="energy">
        <mat-icon>flash_on</mat-icon><span i18n>Energy</span>
      </button>
      <!--
      <a *ngIf="app_type == 'carpool'">
        <button mat-menu-item [routerLink]="['/countbyuser']" routerLinkActive="active">
          <mat-icon>directions_car</mat-icon><span i18n>Groups: Trips</span>
        </button>
        <button mat-menu-item [routerLink]="['/countbydate']" routerLinkActive="active">
          <mat-icon>directions_car</mat-icon><span i18n>Groups: History</span>
        </button> 
      </a>

      <a *ngIf="app_type == 'cartrack'">
        <button mat-menu-item [routerLink]="['/countbymyuser']" routerLinkActive="active">
          <mat-icon>directions_car</mat-icon><span i18n>My Trips</span>
        </button>  
      </a> 
      -->
    
      <button mat-menu-item [routerLink]="['/combinedsettings']" routerLinkActive="active">
        <mat-icon>person_pin</mat-icon><span i18n>Settings</span>
      </button>  
      <ng-container *ngIf=!appUserAgentCheck> 
        <a *ngIf="app_type == 'carpool'">
          <button mat-menu-item [matMenuTriggerFor]="download">
            <mat-icon>cloud_download</mat-icon><span i18n>Download</span>
          </button> 
        </a>
      </ng-container>
      <!--
      <button mat-menu-item [routerLink]="['/traffic']" routerLinkActive="active">
        <mat-icon>info</mat-icon>Trafik
      </button>
      -->  
    </a> 
  </a>
  
  

  <button mat-menu-item [matMenuTriggerFor]="help">
    <mat-icon>help</mat-icon><span i18n>Help</span>
  </button> 
  <button mat-menu-item [matMenuTriggerFor]="language">
    <mat-icon>language</mat-icon><span i18n>Language</span>
  </button>
  

  <button mat-menu-item [routerLink]="['/privacy']" routerLinkActive="active">
    <mat-icon>info</mat-icon><span i18n>Privacy policy</span>
  </button>

  <button mat-menu-item [routerLink]="['/about']" routerLinkActive="active">
    <mat-icon>info</mat-icon><span i18n>About</span>
  </button>
</mat-menu>

<mat-menu #energy="matMenu">
  <button mat-menu-item [routerLink]="['/clever']" routerLinkActive="active">
    <mat-icon>flash_on</mat-icon><span i18n>Clever</span>
  </button>
  <button mat-menu-item [routerLink]="['/forecast']" routerLinkActive="active">
    <mat-icon>flash_on</mat-icon><span i18n>Forecast</span>
  </button>
  <button mat-menu-item [routerLink]="['/powerusage']" routerLinkActive="active">
    <mat-icon>flash_on</mat-icon><span i18n>Power</span>
  </button>

</mat-menu>

<mat-menu #help="matMenu">
  <button mat-menu-item [routerLink]="['/support']" routerLinkActive="active">
    <mat-icon>help</mat-icon><span i18n>Reset password</span>
  </button>
  <button mat-menu-item [routerLink]="['/help']" routerLinkActive="active">
    <mat-icon>help</mat-icon><span i18n>Settings</span>
  </button>

  
</mat-menu>

<mat-menu #language="matMenu">
<ng-container >
  <ul>
    <li *ngFor="let locale of languages">
      <a href="/{{locale.code}}/">
        {{locale.label}}
      </a>
    </li>
  </ul>
</ng-container>
</mat-menu>
<!--
<mat-menu #download="matMenu">
  <button mat-menu-item [routerLink]="['/exceltripsbycount']" routerLinkActive="active">
    <mat-icon>cloud_download</mat-icon><span i18n>Trips</span>
  </button>
  <button mat-menu-item [routerLink]="['/exceltripsbydate']" routerLinkActive="active">
    <mat-icon>cloud_download</mat-icon><span i18n>History</span>
  </button>
</mat-menu>
-->

<div class="container">
  <router-outlet></router-outlet>
</div>  
</div>
</div>
