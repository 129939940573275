<mat-card appearance="outlined" id="card" style="text-align: center;">
  
<div *ngIf="user$ | async; let auser">
: {{auser.customClaims.groups}}
</div>



<mat-card class="mat-elevation-z5" style="text-align: center;">
    <mat-card-header>
        <mat-card-title><span i18n>Power consumption:</span></mat-card-title>
    </mat-card-header>
    <mat-card-content> 
        <div class="flex-row">
            <div class="date">
                <mat-form-field>
                    <input matInput [matDatepicker]="dp" placeholder="Month and Year" [formControl]="date">
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                        (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
                    </mat-datepicker>
                </mat-form-field>
            </div>
            <div class="power"> 
                <img class="image" mat-card-image src="assets/ev_charger.svg" alt="photo">
            </div>   
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="mat-elevation-z5" style="text-align: center;">
    <mat-card-content>
        <div class="flex-row">
            <div class="month_amount">
                <span i18n>Month Amount:</span><br> {{tPower}} <span i18n>Kwh</span>
            </div>
            <div class="month_cost">
                <span i18n>Month Cost:</span><br> {{tCost}} <span i18n>Kr.</span>
            </div>
            <div class="average_cost    ">
                <span i18n>Average Kwh Cost:</span><br> {{average}} <span i18n>Kr.</span>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="my-class-name asd cardPardding" id="card" style="text-align: center;">
<table mat-table id="excel-table" [dataSource]="powerData$">
    
<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>



    <ng-container mat-header-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef><span i18n>Time</span></th>
        <td mat-cell *matCellDef="let element"> {{element.Time | date:'MMM d, HH:mm':'CET'}} </td>

    </ng-container>
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef><span i18n>Amount</span> Kwh</th>
    <td mat-cell *matCellDef="let element"> {{element.Power}} </td>

    </ng-container>
    
    <ng-container matColumnDef="spotPrice">
        <th mat-header-cell *matHeaderCellDef><span i18n>Spot Price</span> kr/Kwh</th>
    <td mat-cell *matCellDef="let element"> {{element.SpotPriceDK1}} </td>

    </ng-container>
    <ng-container mat-header-container matColumnDef="netTarif">
        <th mat-header-cell [hidden]="true" *matHeaderCellDef><span i18n>NetTarif</span></th>
        <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.NetTarif}} </td>
    </ng-container>
        <ng-container mat-header-container matColumnDef="elAfgift">
            <th mat-header-cell [hidden]="true"  *matHeaderCellDef><span i18n>Elafgift</span></th>
            <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.Elafgift}} </td>
        </ng-container>
    <ng-container mat-header-container matColumnDef="balanceTarif">
        <th mat-header-cell [hidden]="true" *matHeaderCellDef><span i18n>BalanceTarif</span></th>
        <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.BalanceTarif}} </td>
    </ng-container>
    <ng-container mat-header-container matColumnDef="systemTarif">
        <th mat-header-cell [hidden]="true" *matHeaderCellDef><span i18n>SystemTarif</span></th>
        <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.SystemTarif}} </td>
    </ng-container>
    <ng-container mat-header-container matColumnDef="transTarif">
        <th mat-header-cell [hidden]="true" *matHeaderCellDef><span i18n>TransTarif</span></th>
        <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.TransTarif}} </td>
    </ng-container>
    <ng-container mat-header-container matColumnDef="extraTotal">
        <th mat-header-cell [hidden]="true" *matHeaderCellDef><span i18n>ExtraTotal</span></th>
        <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.ExtraTotal}} </td>
    </ng-container>
    <ng-container mat-header-container matColumnDef="expense">
        <th mat-header-cell [hidden]="true" *matHeaderCellDef><span i18n>Expense</span></th>
        <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.Expense}} </td>
    </ng-container>
    <ng-container mat-header-container matColumnDef="totalCost">
        <th mat-header-cell  *matHeaderCellDef><span i18n>Total Cost</span></th>
        <td mat-cell  *matCellDef="let element"> {{element.TotalCost}} </td>
    </ng-container>

</table>



</mat-card>
