import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ServerSettingsComponent } from "./server-settings/server-settings.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { MatTableModule } from "@angular/material/table";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatListModule } from "@angular/material/list";
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [ServerSettingsComponent],
  imports: [
    CommonModule,
    //SettingsRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule
  ],
})
export class SettingsServerModule {}
