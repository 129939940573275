import { Component, OnInit } from "@angular/core";
import { PowerService } from "../services/power.service";
import { UserService } from "./../../users/services/user.service";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable, of } from "rxjs";
import { PowerData, PowerUsage } from "../models/power";
import { map, tap } from "rxjs/operators";
import { switchMap, filter } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "../../auth/services/auth.service";
import * as XLSX from "xlsx";
import { User } from "./../../users/models/user";
import { LOCALE_ID, Inject } from "@angular/core";
import {
  UntypedFormControl,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from "moment";

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-power",
  templateUrl: "./power.component.html",
  styleUrls: ["./power.component.scss"],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PowerComponent implements OnInit {
  date = new UntypedFormControl(moment());
  myMonth = "";
  myYear = "";

  powerData$: Observable<PowerUsage[]>;
  user$: Observable<User>;
  tCost = "";
  tPower = "";
  average = "";
  month = "";
  newdata: any;
  displayedColumns: string[] = [
    "time",
    "amount",
    "spotPrice",
    "netTarif",
    "elAfgift",
    "balanceTarif",
    "systemTarif",
    "transTarif",
    "extraTotal",
    "expense",
    "totalCost",
  ];
  fileName = "TeslaPower";
  fileExtension = ".xlsx";

  languages = [
    { code: "en", label: "English" },
    { code: "da", label: "Danish" },
  ];

  constructor(
    private _powerService: PowerService,
    private _router: Router,
    private userService: UserService,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    @Inject(LOCALE_ID) public localeId: string
  ) {}

  ngOnInit(): void {
    const todayDate = new Date();
    const todayDateStr = todayDate.toISOString();
    const myArray = todayDateStr.split("T");
    let dateStr = myArray[0];
    const dateArray = dateStr.split("-");
    let todayYearStr = dateArray[0];
    let todayMonthStr = dateArray[1];
    this.myYear = todayYearStr;
    this.myMonth = todayMonthStr;
    this.getPower();
  }
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    this.myYear = ctrlValue.format("YYYY");
    console.log(ctrlValue.format("YYYY"));
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    console.log(ctrlValue.format("MM"));
    this.myMonth = ctrlValue.format("MM");
    this.getPower();
    datepicker.close();
  }

  findDaySeconds(timeString: string) {
    var a = timeString.split(':'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
    return seconds;
  }
  
  getTarif30Category(daySeconds: number) {
    let tarifCategory = "LowLoad";
    if (daySeconds >= 0 && daySeconds <= 21600) {
      // code to identify Low load period between 0 and 21600 (0-6)
      tarifCategory = "LowLoad";
    } else {
      // code to dentify high load period between 21600 and 61200 (6-17)
      if (daySeconds > 21600 && daySeconds <= 61200) {
        tarifCategory = "HighLoad";
      } else {
        // code to dentify peak load period between 61200 and 75600 (17-21)
        if (daySeconds > 61200 && daySeconds <= 75600) {
          tarifCategory = "PeakLoad";
        } else {
          // code to dentify high load period between 21600 and 86400 (21-24)
          if (daySeconds > 61200 && daySeconds <= 75600) {
            tarifCategory = "HighLoad";
          }
         
        }
      }
    }
     return tarifCategory;
  }

  getTarifPrice(myDateTime: Date) {
    let dateTimeString = myDateTime + "Z";
    //newData["Time"] = dateTimeString;
    let myTimeArr = dateTimeString.split("T");
    let myTimeTmpArr = myTimeArr[1].split("Z");
    let myTime = myTimeTmpArr[0];

    let mySeconds = this.findDaySeconds(myTime);
    let myCategory = this.getTarif30Category(mySeconds);
    let myPrice = this.getN1TarifPrice(myCategory, "Winther");
    console.log("Tarif price: " + myPrice);
    return myPrice;
  }

  getN1TarifPrice(tarifCategory: string, season: string) {
    let NetTarif: Number = 0.0;
    if (tarifCategory == "LowLoad") {
      if (season == "Summer") {
        NetTarif = 0.1101;
      } else {
        NetTarif = 0.1101;
      }
    }
    if (tarifCategory == "HighLoad") {
      if (season == "Summer") {
        NetTarif = 0.1652;
      } else {
        NetTarif = 0.3303;
      }
    }
    if (tarifCategory == "PeakLoad") {
      if (season == "Summer") {
        NetTarif = 0.4295;
      } else {
        NetTarif = 0.991;
      }
    }
   
    return NetTarif;
  }
 
  public getPower() {
    let DataArray = [];
    let monthlyCost: Number = 0;
    let monthlyPower: Number = 0;
    let totalCost: Number = 0;
    let totalPower: Number = 0;

    this.authService.myfirstgroup$
      .pipe(
        switchMap((group) =>
          this._powerService.getdata(group, this.myYear, this.myMonth)
        )
      )
      .subscribe((res) => {
        // console.log(res);
        res.powerUsage.forEach((record, index) => {
          let newData = {};
          //newData["NetTarif"] = 0.432;
          newData["NetTarif"] = 0.0;
          newData["Elafgift"] = 0.0;
          newData["BalanceTarif"] = 0.002;
          newData["SystemTarif"] = 0.054;
          newData["TransTarif"] = 0.058;

          console.log("Her....");
          console.log(record.Time);
          newData["Time"] = record.Time + "Z";
          
          newData["NetTarif"] = this.getTarifPrice(record.Time);

          newData["ExtraTotal"] =
            Number(newData["NetTarif"]) +
            Number(newData["Elafgift"]) +
            Number(newData["BalanceTarif"]) +
            Number(newData["SystemTarif"]) +
            Number(newData["TransTarif"]);


          newData["Power"] = record.Power;
          newData["SpotPriceDK1"] = record.SpotPriceDK1;
          newData["SpotPriceDK2"] = record.SpotPriceDK2;
          newData["Expense"] =
            Number(newData["ExtraTotal"]) + Number(newData["SpotPriceDK1"]);
          newData["TotalCost"] =
            Number(newData["Power"]) * Number(newData["Expense"]);
          newData["TotalCost"] = newData["TotalCost"].toFixed(2);
          totalCost = newData["TotalCost"];
          totalPower = newData["Power"];

          monthlyCost = +monthlyCost + +totalCost;
          monthlyPower = +monthlyPower + +totalPower;
          DataArray.unshift(newData);
          //console.log(monthlyCost);

          let date = new Date();
          let myDate = "";
          if (this.localeId == "en") {
            myDate = date.toLocaleString("en-GB", { month: "long" });
          }
          if (this.localeId == "da") {
            myDate = date.toLocaleString("da-DK", { month: "long" });
          }

          this.month = myDate.charAt(0).toUpperCase() + myDate.slice(1);
        });

        this.powerData$ = of(DataArray);
        this.tCost = String(monthlyCost.toFixed(2));
        this.tPower = String(monthlyPower.toFixed(2));
        let aveNum = +monthlyCost / +monthlyPower;
        this.average = String(aveNum.toFixed(2));
      });
    //this.isLoading = false;
  }

  reloadComponent() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = "reload";
    this._router.navigate(["/power"]);
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const myDateString = today.toUTCString();

    let fileInfo = "";

    fileInfo = this.fileName + "_" + myDateString + this.fileExtension;

    /* save to file */
    XLSX.writeFile(wb, fileInfo);
  }
}
