<mat-card appearance="outlined" id="card" style="text-align: center;">
<mat-card-title><span i18n>Forecast and market price:</span></mat-card-title>

<table mat-table id="excel-table" [dataSource]="energyData$">

    <ng-container mat-header-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef><span i18n>Time</span></th>
        <td mat-cell *matCellDef="let element"> {{element.time}} </td>
    </ng-container>

        <ng-container matColumnDef="spotPrice">
            <th mat-header-cell *matHeaderCellDef><span i18n>Spot Price</span> kr/Kwh</th>
            <mat-chip-listbox>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.spotpriceStatus === 'low'">
                        
                        <mat-chip-option  highlighted color="primary">{{element.spotPrice}}</mat-chip-option>
                    </div>
                    <div *ngIf="element.spotpriceStatus === 'medium'">
    
                        <mat-chip-option  highlighted color="accent">{{element.spotPrice}}</mat-chip-option>
                    </div>
                    <div *ngIf="element.spotpriceStatus === 'high'">
    
                        <mat-chip-option  highlighted color="warn">{{element.spotPrice}}</mat-chip-option>
                    </div>
                </td>
            </mat-chip-listbox>
        </ng-container>
    
    <ng-container matColumnDef="forecastPrice">
        <th mat-header-cell *matHeaderCellDef><span i18n>Forecast Price Index</span></th>
        <mat-chip-listbox>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.forecastStatus === 'low'">
                    <mat-chip-option highlighted color="primary">{{element.forecastPrice}}</mat-chip-option>
                </div>
                <div *ngIf="element.forecastStatus === 'medium'">
                    <mat-chip-option highlighted color="accent">{{element.forecastPrice}}</mat-chip-option>
                </div>
                <div *ngIf="element.forecastStatus === 'high'">
                    <mat-chip-option highlighted color="warn">{{element.forecastPrice}}</mat-chip-option>
                </div>
            </td>
        </mat-chip-listbox>
        <td mat-cell *matCellDef="let element"> {{element.forecastPrice}} </td>
    </ng-container>


    <ng-container matColumnDef="spotpriceStatus">
        <th mat-header-cell *matHeaderCellDef><span i18n>Spot Price Status</span></th>
        <td mat-cell *matCellDef="let element"> {{element.spotpriceStatus}} </td>
    </ng-container>

    <ng-container matColumnDef="forecastStatus">
        <th mat-header-cell *matHeaderCellDef><span i18n>Forecast Status</span></th>
        <td mat-cell *matCellDef="let element"> {{element.forecastStatus}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!--

   
    <tr mat-row *matRowDef="let row; columns: displayedColumns;let entry"
        [ngClass]="{ 'highlight': entry.spotpriceStatus == 'low' }"></tr>
-->
    </table>

</mat-card>