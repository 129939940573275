
<!-- <mat-card style="max-width: 800px;"> -->
<div class="auto" *ngIf="appType$ | async; let app_type">  
  <div *ngIf="appMode$ | async; let app_mode">  
    <mat-card appearance="outlined" id="card" style="text-align: center;">
    <br>

    <div *ngIf="secondThemeSelected">
      <img mat-card-image src="assets/car-teal.svg" alt="photo">   
    </div>

    <!-- Negation operator-->
    <div *ngIf="!secondThemeSelected">
      <img  mat-card-image src="assets/home.svg" alt="photo">
      <img  mat-card-image src="assets/porsevej.svg" alt="photo">
    </div> 

      <div *ngIf="app_type == 'powertrack'; else elseBlock">
        <h1 class="mat-headline-4">PowerTrack</h1>
        <h1 class="mat-headline-4"><span i18n>A system to monitor power usage and cost.</span></h1>
      </div>
      <ng-template #elseBlock>
        <h1 class="mat-headline-4">Wrong FrontEND !!!!!!</h1>
        <h1 class="mat-headline-4"><span i18n>Time spent by people when driving in a carpool group</span></h1>
      </ng-template>

      <p *ngIf="app_mode == 'demo'">
        <span i18n>In order to try it out, use:</span>
        <br>
        <br>
        Login : testuser1@porsevej.dk <br>
        Password: Pass1word <br>
      </p>  
    </mat-card>
  </div>  
</div>

