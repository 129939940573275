<div class="box">
<!--<mat-card appearance="outlined"> -->
  
<div *ngIf="appType$ | async; let app_type">
<div *ngIf="user$ | async; let auser">
<div *ngIf="userTokens$ | async; let user_tokens">
<mat-card class="my-class-name asd cardPardding" appearance="outlined">
  <mat-card-title><span i18n>Group</span>
<div *ngIf="auser.customClaims.groups == 'admin'; then thenBlock else elseBlock"></div>
<ng-template #thenBlock>
  <!-- admin -->
  : {{getGroup()}} 
</ng-template>
<ng-template #elseBlock>
  <!-- Not admin -->
  : {{auser.customClaims.groups}}
</ng-template>
</mat-card-title>
</mat-card>

<!-- class="list-group" -->

  <div *ngIf="settingsGroup$ | async as settingsGroup; else indicator">
    <div *ngIf="appType$ | async; let app_type">
   <!-- <mat-list> -->
      <div>
      <mat-list-item *ngFor="let user of settingsGroup">
        <mat-card class="my-class-name asd cardPardding">
          <div class="flex-row">
            <div class="text1">
              <div *ngIf="app_type == 'cartrack' && (user.Options == '2' || user.CurrentUser == user.UserId  || auser.customClaims.groups == 'admin')" matLine><span i18n>Name : </span>{{user.Name}}</div>
              <div *ngIf="app_type == 'carpool'" matLine><span i18n>Name</span> : {{user.Name}}</div>
              <div *ngIf="(user.CurrentUser == user.UserId || auser.customClaims.groups == 'admin') && (user.Options == '1')" matLine>
                <span i18n>Name : </span>{{user.Name}} 
              </div>
              <div *ngIf="user.CurrentUser == user.UserId  || auser.customClaims.groups == 'admin'" matLine><span i18n>User-ID</span> : {{user.UserId}}
              </div>
            </div>
            <div class="text2">
              <div *ngIf="user.CurrentUser == user.UserId  || auser.customClaims.groups == 'admin'" matLine><span i18n>Email</span> : {{user.Email}}
              <button mat-raised-button color="primary" (click)="emailToken(user)"><span i18n>Email
                  Token</span></button>
              </div>
                <div *ngIf="(user.CurrentUser == user.UserId || auser.customClaims.groups == 'admin') && (user.Options == '1')" matLine><span i18n>Short name : </span>{{user.ShortName}}  
                <button mat-raised-button  color="primary" (click)="openEditDialog(user)"><span i18n>Edit</span></button>
              </div>
            </div>
          </div>
        
        <div *ngIf="(user.CurrentUser == user.UserId || auser.customClaims.groups == 'admin') && (user.Options == '1')"matLine>
        </div>  
        <div *ngIf="(auser.customClaims.groups == 'admin') && (user.Options == '2')"matLine>
          <button mat-raised-button style="float: right;" color="primary" (click)="openEditDialog(user)"><span i18n>Edit</span></button>
        </div>  
        <div class="box" *ngIf="(user.Options == '2') || (app_type == 'carpool')"matLine>
          <div *ngIf="app_type == 'cartrack' && (auser.customClaims.groups == 'admin')" matLine><span i18n>Car-ID</span> : {{user.CarId}}</div>
          <p *ngIf="app_type == 'cartrack' && (auser.customClaims.groups == 'admin')" matLine><span i18n>Battery change date</span> : {{user.BatteryChangeDate}}</p>
          <p *ngIf="app_type == 'carpool' && (user.CurrentUser == user.UserId  || auser.customClaims.groups == 'admin')" matLine><span i18n>Car-ID</span> : {{user.CarId}}</p>  
          <p *ngIf="app_type == 'carpool' && (user.CurrentUser == user.UserId  || auser.customClaims.groups == 'admin')" matLine>
            <span i18n>Battery change date</span> : {{user.BatteryChangeDate}}</p>
          <p *ngIf="app_type == 'cartrack' && (user.Options == '2' || auser.customClaims.groups == 'admin')" matLine><span i18n>Car model</span> : {{user.CarModel}}</p>

          <p *ngIf="app_type == 'carpool'" matLine><span i18n>Car model</span> : {{user.CarModel}}</p>
          <p *ngIf="app_type == 'cartrack' && (user.Options == '2' || auser.customClaims.groups == 'admin')" matLine><span i18n>Licenseplate</span> : {{user.LicensePlate}}</p>
            <div *ngIf="app_type == 'carpool'  && (user.CurrentUser == user.UserId || auser.customClaims.groups == 'admin')" matLine>
              <button mat-raised-button style="float: right;" color="primary" (click)="openAddTripDialog(user)"><span i18n>Add
                  Trip</span></button>
            </div>
            <div *ngIf="app_type == 'cartrack' && (user.Options == '2' || auser.customClaims.groups == 'admin')" matLine>
              <button mat-raised-button style="float: right;" color="primary" (click)="openAddTripDialog(user)"><span i18n>Add
                  Trip</span></button>
            </div>

          <p *ngIf="app_type == 'carpool'" matLine><span i18n>Licenseplate</span> : {{user.LicensePlate}}</p>
          <p *ngIf="app_type == 'cartrack' && (auser.customClaims.groups == 'admin')" matLine><span i18n>Sensor code</span> : {{user.UuidMajor}}</p>
          <p *ngIf="app_type == 'carpool' && (user.CurrentUser == user.UserId  || auser.customClaims.groups == 'admin')" matLine><span i18n>Sensor code</span> : {{user.UuidMajor}}</p>
          <p *ngIf="app_type == 'cartrack' && (auser.customClaims.groups == 'admin')" matLine><span i18n>Sensor battery status</span> : {{user.BattStatus}}</p>
          <p *ngIf="app_type == 'carpool' && (user.CurrentUser == user.UserId  || auser.customClaims.groups == 'admin')" matLine><span i18n>Battery</span> : {{user.BattStatus}}</p>
        </div>  

        </mat-card>
     <!--   <mat-divider></mat-divider> -->
      </mat-list-item>
     <!-- </mat-card> -->
      </div>
   <!-- </mat-list> -->
    
       
  </div>
  <ng-template #indicator>
    <loading-indicator>Loading...</loading-indicator>
  </ng-template> 
</div>
</div>
</div>
</div>
<!-- </mat-card> -->
<mat-card appearance="outlined" *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner diameter="300"
    color="primary" 
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>
</div>


