import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import SettingsJson from "../../../assets/appsettings.json";
import { Convert, PowerData, PowerUsage } from "../models/power";

@Injectable({
  providedIn: "root",
})
export class PowerService {
  constructor(private _http: HttpClient) {
    console.log("PowerService constructor: ");
  }

  getdata(url_param: string, year: string, month: string) {
    // , year: string, month: string
    console.log(year);
    console.log(month);
    let baseUrl = environment.userApi;
    let groupConfigBaseUrl = baseUrl + SettingsJson.powerUsageUrl2;
    console.log("powerUsageBaseUrl: " + groupConfigBaseUrl);

    // Add safe, URL encoded search parameter
    url_param = url_param.trim();
    const options = url_param
      ? {
          params: new HttpParams()
            .set("group", url_param)
            .set("year", year)
            .set("month", month),
        }
      : {};
    return this._http.get<PowerData>(groupConfigBaseUrl, options);
  }
}
