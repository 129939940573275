
<mat-card  class="my-class-name asd cardPardding">
  <mat-card-title><span i18n>All Users</span></mat-card-title>
  <mat-card-actions>
  <button mat-raised-button color="primary" (click)="newUserDialog()"><span i18n>New User</span></button>
  </mat-card-actions>
</mat-card>

<mat-card class="my-class-name asd cardPardding">
  <div *ngIf="users$ | async as users; else indicator" class="list-group">
    {{user}} 
   <!-- <mat-list> -->
      <mat-list-item *ngFor="let user of users" >
        <mat-card class="my-class-name asd cardPardding">
          <mat-card-content>
              <div class="flex-row">
                <div class="text">
                  <p matLine>{{user.displayName}}</p>
                  <p matLine>{{user.email}}</p>
                  <p matLine>{{user.uid}}</p>
                  
                </div>
                <div class="groupandroles">
                  <p matLine>
                    <mat-chip-listbox>
                      <mat-chip-option color="primary" selected>{{user.groups}}</mat-chip-option>
                    </mat-chip-listbox>
                  </p>
                  <p matLine>
                    <mat-chip-listbox>
                      <mat-chip-option color="accent" selected>{{user.role}}</mat-chip-option>
                    </mat-chip-listbox>
                  </p>
                </div>
              </div>
          </mat-card-content>
          <mat-card-actions>
              <button mat-raised-button color="primary" (click)="openDeleteDialog(user)"><span i18n>Delete</span></button>
          </mat-card-actions>
         
       
          
 
        </mat-card>  
      </mat-list-item>
    <!-- </mat-list> -->
  </div>
</mat-card>

<ng-template #indicator>
  <mat-card appearance="outlined" style="display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner diameter="300"
      color="primary" 
      mode="indeterminate">
    </mat-progress-spinner>
  </mat-card>
    

</ng-template>

  