<mat-card>
  <mat-card-title>Login</mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <p>
        <mat-form-field>
          <mat-label><span i18n>Email</span></mat-label>
          <input type="text" matInput placeholder="Username" formControlName="username">
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <mat-label><span i18n>Password</span></mat-label>
          <input type="password" matInput placeholder="Password" formControlName="password">
        </mat-form-field>
      </p>

      <p *ngIf="loginInvalid" class="error">
        <span i18n>User or Password not recognized</span>
      </p>

      <div class="button">
        <button type="submit" mat-raised-button><span i18n>Login</span></button>
      </div>

    </form>
  </mat-card-content>
</mat-card>
